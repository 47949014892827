$(document).on("turbo:load", () => {
  $("#zip-upload").on("change", function () {
    $(".file2Upload").remove();
    for (let i = 0; i < this.files.length; i++) {
      let file = this.files[i];
      let li = document.createElement("li");
      li.className = "file2Upload";
      li.innerText = file.name;
      $("#file-list").append(li);
    }

    $("#files-count").text(this.files.length);
  });
});
